export default {
  home: {
    home1: '未來數據集團有限公司',
    home2: '歡迎來到未來數據集團（8229.HK），我們致力於構建Web3.0時代的數據空間托管解決方案，以推動元宇宙技術的發展和應用。作為行業領先者，我們為您提供最先進的數據托管服務，助力您在數字世界中實現無限可能。',
    home3: 'Web3.0 應用',
    home4: 'Web3.0是互聯網的下一代演進。在這個新時代，我們將數據、身份和價值重新定義，通過去中心化、安全性和透明度為用戶創造更加開放和民主的網絡環境。未來數據秉承Web3.0精神，致力於為用戶打造安全可信賴的數據存儲與交換平臺，讓每個人都能充分掌握自己的數據權利。',
    home5: '探索無限可能',
    home6: '未來數據的Web3.0應用涵蓋了多領域的創新技術，包括區塊鏈、智能合約和去中心化應用程序。通過我們的平臺，您可以探索並體驗到底是什麽讓Web3.0如此具有變革性，以及它如何重新定義人與技術的關系。',
    home7: '數據驅動創新',
    home8: '我們深知數據的價值，因此我們的Web3.0應用將為您打開全新的「數據大門」。借助先進的數據分析和加密技術，我們將幫助您更好地理解和利用數據，從而實現前所未有的商業創新和發展。',
    home9: '個性化用戶體驗',
    home10: '未來數據將為每一位用戶提供個性化的服務和體驗。無論您是開發者、企業家還是普通用戶，我們都將根據您的需求和偏好，為您量身定製最適合您的Web3.0解決方案。',
    home11: '元宇宙技術',
    home12: '隨著元宇宙技術的崛起，虛擬世界與現實世界之間的邊界日益模糊。未來數據將深度融合元宇宙技術，為用戶提供數據空間的多維度展示與交互體驗，讓您能夠在數字世界中暢遊，探索全新的虛擬體驗。',
    home13: '重塑現實，開啟元宇宙時代',
    home14: '通過結合虛擬現實、增強現實和區塊鏈技術，我們將為您構建真實世界與虛擬世界的連接橋梁。無論是遊戲、社交、教育還是商業，未來數據的元宇宙應用將為用戶帶來身臨其境的體驗，重新定義人們對互聯網的認知。',
    home15: '創新驅動，引領未來',
    home16: '我們以創新為核心，不斷探索元宇宙應用的邊界。從視覺效果到社交互動，我們借助最先進的技術和設計理念，為您打造獨一無二的元宇宙體驗，讓每一個用戶都能找到屬於自己的數字樂園。',
    home17: '合作共贏，開啟無限可能',
    home18: '無論您是企業還是個人開發者，我們都歡迎與您攜手合作，共同探索元宇宙的無限可能。我們相信，通過合作共贏的模式，可以創造出更多令人驚嘆的數字奇跡，讓每個人都成為未來的創造者。',
    home19: '數據空間托管業務',
    home20: '我們的數據空間托管業務涵蓋了各種形式的數據存儲與管理需求，從個人數據到企業級數據，我們都能提供最優質的解決方案。無論是文件存儲、數據庫管理還是區塊鏈數據托管，未來數據都能為您提供高效、安全的服務，助力您在數字經濟時代搶先一步。',
    home21: '安全可靠的數據存儲',
    home22: '無論是個人用戶還是企業，您的數據都將受到最嚴格的保護。我們采用先進的加密技術和多重備份策略，確保您的數據在任何情況下都能得到最完善的保障和恢復。',
    home23: '數據管理的智慧之選',
    home24: '未來數據的數據托管服務不僅提供存儲功能，更註重為您提供高效的數據管理工具。通過我們的平臺，您可以輕松地對數據進行分類、檢索和分享，使您的數據管理變得更加便捷與智能。',
    home25: '定製化解決方案',
    home26: '我們深知每位客戶的需求各不相同，因此我們提供個性化的數據托管解決方案。無論您需要托管大規模數據還是小型文件，我們都將根據您的實際需求量身定製最適合您的方案。',
    home27: '可靠的合作夥伴',
    home28: '與未來數據合作，將讓您獲得一個值得信賴的合作夥伴。我們將秉持誠信、負責和專業的態度，與您攜手共同打造更加安全、穩定的數據托管環境。',
    home29: '公告',
    home30: '日期',
    home31: '標題',
    home32: '分類',
    home33: '下載',
    home34: '投資者關係',
    home35: '公司資訊',
    home36: '董事會',
    home37: '執行董事',
    home38: '陶虹遐女士（主席）',
    home39: '李承翰先生（行政總裁）',
    home40: '張霆邦先生',
    home41: '獨立非執行董事',
    home42: '陳建明先生',
    home43: '林智祥先生',
    home44: '梁浩鳴先生',
    home45: '董事委員會',
    home46: '審核委員會',
    home47: '陳建明先生（主席）',
    home48: '林智祥先生',
    home49: '梁浩鳴先生',
    home50: '薪酬委員會',
    home51: '林智祥先生（主席）',
    home52: '張霆邦先生',
    home53: '陳建明先生',
    home54: '提名委員會',
    home55: '梁浩鳴先生（主席）',
    home56: '張霆邦先生',
    home57: '林智祥先生',
    home58: '陳建明先生',
    home59: '合規主任',
    home60: '李承翰先生',
    home61: '聯席公司秘書',
    home62: '張月芬女士',
    home63: '張霆邦先生',
    home64: '授權代表',
    home65: '陶虹遐女士',
    home66: '張月芬女士',
    home67: '開曼群島註冊辦事處',
    home68: '香港主要營業地點',
    home69: '香港灣仔港灣道1號會展廣場辦公大樓17樓1703室',
    home70: '香港股份過戶登記分處',
    home71: '卓佳證券登記有限公司    香港夏愨道16號遠東金融中心17樓',
    home72: '總部及韓國主要營業地點',
    home73: '核數師',
    home74: '羅申美會計師事務所執業會計師    香港銅鑼灣恩平道28號利園二期29樓',
    home75: '合規顧問',
    home76: '智富融資有限公司   香港中環雲咸街8號3樓',
    home77: '股份代號',
    home78: '公司文件及政策',
    home79: '投資者查詢',
    home80: '有關投資上的所有査詢，歡迎以電郵聯絡：',
    home81: '聯繫我們',
    home82: '香港辦公室',
    home83: '未來數據集團有限公司',
    home84: '香港灣仔港灣道1號會展廣場辦公大樓17樓1703室',
    home85: '電話：+852 3622  1452',
    home86: '傳真：+852 3568 2576',
  },
  language: {
    language: '繁體中文',
    zh: 'zh-CN',
    en: 'en',
    zhName: '繁體中文',
    enName: 'English',
},
}