<template>
  <div class="home">
    <div class="one">
      <h1>AI Lawyer</h1>
      <h2>人工智能让法律事务</h2>
      <h3>更快、更准</h3>
      <el-button type="primary" round @click="$router.push('/chat')">立即使用</el-button>
    </div>
    <div class="two">
      <h1><span>智能</span>法律咨询</h1>
      <h2>分析和理解用户的法律问题， 提供基于法规与判例的专业回答</h2>
    </div>
    <div class="three">
      <h1>法律文书<span>自动</span>生成</h1>
      <h2>
        无需复杂的流程和昂贵的律师费用，<br />
        平台可以根据您的咨询内容自动生成专业的法律文书
      </h2>
    </div>
    <div class="four">
      <h1><span>专业</span>的律师团队</h1>
      <h2>
        如果您还需要更专业的服务，<br />
        我们有专业的律师给为您提供一对一服务
      </h2>
    </div>
    <div class="five">
      <h1>为什么选择我们</h1>
      <div class="content">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img :src="item.img" alt="" />
          <h2>{{ item.title }}</h2>
          <h3>{{ item.text }}</h3>
        </div>
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script>
import FooterBar from "@/components/footer.vue";
export default {
  name: "HomeView",
  components: {
    FooterBar,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    list() {
      return [
        {
          img: require(`@/assets/image/item1.png`),
          title: "专业保障",
          text: "我们的AI系统由顶尖的法律专家和技术团队精心打造，确保每个答案和文书都符合最新的法律规定和标准",
        },
        {
          img: require(`@/assets/image/item2.png`),
          title: "迅捷高效",
          text: "利用先进的AI技术，我们能够在几秒钟内为您提供答案和文书，让您不再为法律问题烦恼",
        },
        {
          img: require(`@/assets/image/item4.png`),
          title: "用户友好",
          text: "简洁直观的平台设计，让您轻松上手。只需几个简单步骤，就能获得专业的法律服务",
        },
        {
          img: require(`@/assets/image/item3.png`),
          title: "全天候服务",
          text: "无论何时何地，我们的服务都随时在线，满足您的所有法律需求",
        },
      ];
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  .one {
    width: 100%;
    height: 7rem;
    box-sizing: border-box;
    background: url("~@/assets/image/1.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 2.42rem;
    padding-top: 2.05rem;
    h1 {
      height: 0.24rem;
      font-family: DM Sans, DM Sans;
      font-weight: bold;
      font-size: 0.3rem;
      color: #4091f7;
      line-height: 0.24rem;
    }
    h2 {
      height: 0.7rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 300;
      font-size: 0.4rem;
      color: #12022f;
      line-height: 1.1rem;
    }
    h3 {
      height: 1.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.5rem;
      color: #12022f;
      line-height: 1.1rem;
      margin-bottom: 0.58rem;
    }
  }
  .two {
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    background: url("~@/assets/image/2.png") no-repeat;
    background-size: 100% 100%;
    padding-right: 2.54rem;
    padding-top: 1.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h1 {
      height: 0.89rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.42rem;
      color: #12022f;
      line-height: 0.89rem;
      margin-right: 0.28rem;
      span {
        color: #2f80ed;
      }
    }
    h2 {
      width: 2.8rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.2rem;
      color: #12022f;
      line-height: 0.3rem;
    }
  }
  .three {
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    background: url("~@/assets/image/3.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 2.42rem;
    padding-top: 1.61rem;
    h1 {
      height: 0.89rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.42rem;
      color: #12022f;
      line-height: 0.89rem;
      margin-right: 0.28rem;
      span {
        color: #2f80ed;
      }
    }
    h2 {
      width: 4.6rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.2rem;
      color: #12022f;
      line-height: 0.3rem;
    }
  }
  .four {
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    background: url("~@/assets/image/4.png") no-repeat;
    background-size: 100% 100%;
    padding-right: 1.74rem;
    padding-top: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h1 {
      height: 0.89rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.42rem;
      color: #12022f;
      line-height: 0.89rem;
      margin-right: 0.66rem;
      span {
        color: #2f80ed;
      }
    }
    h2 {
      width: 3.6rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.2rem;
      color: #12022f;
      line-height: 0.3rem;
    }
  }
  .five {
    width: 100%;
    height: 7.14rem;
    box-sizing: border-box;
    background: url("~@/assets/image/5.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .91rem 1.9rem 0 1.9rem;
    h1 {
      height: 0.89rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.42rem;
      color: #12022f;
      line-height: 0.89rem;
      margin-bottom: .87rem;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 1.42rem;
          height: 1.42rem;
          margin-bottom: 0.3rem;
        }
        h2 {
          height: 0.39rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 0.24rem;
          color: #12022f;
          line-height: 0.28rem;
        }
        h3 {
          width: 2.15rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.16rem;
          color: #594d6d;
          line-height: 0.26rem;
        }
      }
    }
  }
  .el-button {
    width: 1.58rem;
    height: 0.56rem;
    border-radius: 0.28rem;
    font-weight: bold;
font-size: 0.2rem;
color: #FFFFFF;
  }
}
</style>
