import router from "./index";
import store from "../store/index"

// 导航守卫
router.beforeEach((to, from, next) => {
  window,scrollTo(0,0);
  // if(store.state.loginShow) {
  //   store.commit('setLoginShow', false)
  // }
  
  // const token = store.state.userInfo.accessToken
  // const url = to.path
  // const whiteList = ['/', '/login', '/osg'] 
  // if(token && url === '/login'){
      next()
  //     return
  // }
  // // 未登录拦截
  // if(!token && !whiteList.includes(url)){
  //   next('/login')
  //   return
  // }
  // next()
})