export default {
  home: {
    home1: 'Future Data',
    home2: 'Welcome to Future Data Group (8229.HK), where we are dedicated to building data space hosting solutions for the Web3.0 era, driving the development and application of metaverse technology. As industry leaders, we offer cutting-edge data hosting services to empower you in the digital world, unlocking limitless possibilities.',
    home3: 'Web3.0 Applications',
    home4: 'Web3.0 marks the next evolution of the internet, redefining data, identity, and value. Future Data, as a pioneer, adheres to the Web3.0 spirit, striving to create a secure and trustworthy platform for data storage and exchange, ensuring everyone has control over their data rights.',
    home5: 'Explore Infinite Possibilities',
    home6: "Future Data's Web3.0 applications span various innovative technologies, including blockchain, smart contracts, and decentralized applications. Through our platform, you can explore what makes Web3.0 revolutionary and how it redefines the relationship between people and technology.",
    home7: 'Data-Driven Innovation',
    home8: 'Recognizing the value of data, our Web3.0 applications open the "data gateway" for you. With advanced data analytics and encryption, we help you better understand and leverage data, fostering unprecedented business innovation and development.',
    home9: 'Personalized User Experience',
    home10: 'Future Data tailors personalized services and experiences for every user, whether you are a developer, entrepreneur, or regular user. We customize the best Web3.0 solutions based on your needs and preferences.',
    home11: 'Metaverse Technology',
    home12: 'As metaverse technology rises, the boundaries between virtual and real worlds blur. Future Data deeply integrates metaverse technology, offering users multi-dimensional displays and interactive experiences in data space, enabling exploration in the digital world.',
    home13: 'Reshaping Reality in the Metaverse Era',
    home14: "Combining virtual reality, augmented reality, and blockchain, we build the bridge between the real and virtual worlds. Future Data's metaverse applications immerse users in experiences, whether in gaming, socializing, education, or business, redefining perceptions of the internet.",
    home15: 'Innovation-Driven Leadership',
    home16: 'Innovation is at our core as we continually push the boundaries of metaverse applications. From visual effects to social interaction, we use cutting-edge technology and design principles to create a unique metaverse experience for each user.',
    home17: 'Collaborative Success, Infinite Possibilities',
    home18: 'Whether you are an enterprise or an individual developer, we welcome collaboration to explore the limitless possibilities of the metaverse. Through a win-win partnership, we believe in creating astonishing digital wonders, turning everyone into creators of the future.',
    home19: 'Data Space Hosting Business',
    home20: "Our data space hosting business covers various forms of data storage and management needs, from personal to enterprise-level data. Whether it's file storage, database management, or blockchain data hosting, Future Data provides efficient and secure services, helping you stay ahead in the digital economy.",
    home21: 'Secure and Reliable Data Storage',
    home22: 'Your data, whether personal or business-related, receives the strictest protection. We employ advanced encryption and multiple backup strategies to ensure your data is impeccably safeguarded and recoverable under any circumstances.',
    home23: 'Intelligent Data Management',
    home24: "Beyond storage, Future Data's data hosting services focus on providing efficient data management tools. Through our platform, you can easily categorize, retrieve, and share data, making your data management more convenient and intelligent.",
    home25: 'Customized Solutions',
    home26: 'Understanding the unique needs of each customer, we offer personalized data hosting solutions. Whether you require hosting for large-scale data or small files, we tailor the most suitable solutions based on your actual needs.',
    home27: 'Reliable Partnership',
    home28: 'Collaborating with Future Data ensures a trustworthy partnership. We uphold integrity, responsibility, and professionalism, working hand in hand to create a safer and more stable data hosting environment.',
    home29: 'Announcement',
    home30: 'Date',
    home31: 'Title',
    home32: 'Category',
    home33: 'Download',
    home34: 'Investor Relations',
    home35: 'Company information',
    home36: 'BOARD OF DIRECTORS',
    home37: 'Executive Directors',
    home38: 'Ms. Tao Hongxia (Chairlady)',
    home39: 'Mr. Lee Seung Han (Chief Executive Officer)',
    home40: 'Mr. Cheung Ting Pong',
    home41: 'Independent Non-executive Directors',
    home42: 'Mr. Chan Kin Ming',
    home43: 'Mr. Lam Chi Cheung Albert',
    home44: 'Mr. Leung Louis Ho Ming',
    home45: 'BOARD COMMITTEES',
    home46: 'Audit Committee',
    home47: 'Mr. Chan Kin Ming (Chairman)',
    home48: 'Mr. Lam Chi Cheung Albert',
    home49: 'Mr. Leung Louis Ho Ming',
    home50: 'Remuneration Committee',
    home51: 'Mr. Lam Chi Cheung Albert (Chairman)',
    home52: 'Mr. Cheung Ting Pong',
    home53: 'Mr. Chan Kin Ming',
    home54: 'Nomination Committee',
    home55: 'Mr. Leung Louis Ho Ming (Chairman)',
    home56: 'Mr. Cheung Ting Pong',
    home57: 'Mr. Lam Chi Cheung Albert',
    home58: 'Mr. Chan Kin Ming',
    home59: 'COMPLIANCE OFFICER',
    home60: 'Mr. Lee Seung Han',
    home61: 'JOINT COMPANY SECRETARIES',
    home62: 'Ms. Cheung Yuet Fan',
    home63: 'Mr. Cheung Ting Pong',
    home64: 'AUTHORISED REPRESENTATIVES',
    home65: 'Ms. Tao Hongxia',
    home66: 'Ms. Cheung Yuet Fan',
    home67: 'REGISTERED OFFICE IN THE CAYMAN ISLANDS',
    home68: 'PRINCIPAL PLACE OF BUSINESS IN HONG KONG',
    home69: 'Room 1703, 17/F Office Tower, Convention Plaza No. 1 Harbour Road Wan Chai, Hong Kong',
    home70: 'HONG KONG BRANCH SHARE REGISTRAR AND TRANSFER OFFICE',
    home71: 'Tricor Investor Services Limited 17/F, Far East Finance Centre 16 Harcourt Road Hong Kong',
    home72: 'HEAD OFFICE AND PRINCIPAL PLACE OF BUSINESS IN KOREA',
    home73: 'AUDITOR',
    home74: 'RSM Hong Kong Certified Public Accountants 29th Floor, Lee Garden Two 28 Yun Ping Road Causeway Bay Hong Kong ',
    home75: 'COMPLIANCE ADVISER',
    home76: 'Giraffe Capital Limited 3/F, 8 Wyndham Street Central, Hong Kong',
    home77: 'STOCK CODE',
    home78: 'Company documents & Policies',
    home79: 'Investor inquiries',
    home80: 'For all inquiries regarding investment, please contact us via email:',
    home81: 'Contact Us',
    home82: 'HongKong Office',
    home83: 'Future Data Group Limited',
    home84: 'Room 1703, 17/F Office Tower, Convention Plaza No. 1 Harbour Road Wan Chai, Hong Kong',
    home85: 'Tel: +852 3622  1452',
    home86: 'Fax: +852 3568 2576',
  },
  language: {
    language: 'English',
    zh: 'zh-CN',
    en: 'en',
    zhName: '繁體中文',
    enName: 'English',
},
}