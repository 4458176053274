import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, getToken, remToken } from '../store/token'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: getToken() || '',
    language: localStorage.getItem('changeLang')
      ? localStorage.getItem('changeLang')
      : 'zh-CN',
    loginShow: false,
  },
  getters: {},
  mutations: {
    setTokenInfo(state, newSetToken) {
      state.userInfo = newSetToken
      setToken(newSetToken)
    },
    remTokenInfo(state) {
      state.userInfo = {}
      remToken()
    },
    // 同步操作直接修改state里面的語言数据
    setLanguage: (state, data) => {
      state.language = data
      localStorage.setItem('changeLang', data)
    },
    setLoginShow: (state, data) => {
      state.loginShow = data
    },
  },
  actions: {},
  modules: {},
})
