<template>
  <div class="footer">
    <div class="top">
      <div class="left">AI Lawyer</div>
      <ul>
        <li class="title">法律与法规</li>
        <li>服务协议</li>
        <li>隐私策略</li>
      </ul>
    </div>
    <div class="bottom">
      本服务生成内容由AI生成，我们不能保证生成内容无误、准确和完整。相关内容仅为您提供辅助参考，并不代表法律专业意见，不能代替法律专业人员的解答。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 2rem;
  box-sizing: border-box;
  background: #fff;
  padding-left: 1.86rem;
  padding-right: 1.57rem;
  padding-top: 0.84rem;
  padding-bottom: 0.11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      height: 0.24rem;
      font-family: DM Sans, DM Sans;
      font-weight: bold;
      font-size: 0.3rem;
      color: #4091f7;
      line-height: 0.24rem;
    }
    ul {
      display: flex;
      .title {
        font-weight: 600;
      }
      li {
        height: 0.24rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.2rem;
        color: #12022f;
        line-height: 0.24rem;
        margin-left: .72rem;
      }
    }
  }
  .bottom {
    height: 0.3rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 0.1rem;
    color: #594d6d;
    line-height: 0.3rem;
    text-align: center;
  }
}
</style>
